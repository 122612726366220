<template>
  <div>
    <div class="box">
      <!-- 头部 -->
      <headers></headers>
      <!-- 轮播图 -->
      <el-carousel :interval="3000">
        <el-carousel-item>
          <img src="@/assets/homePage/ja-关于盛泰banner.png" class="images">
        </el-carousel-item>
      </el-carousel>
      <!-- 内容 -->
      <div class="content">
        <h2 class="H2">会社情報</h2>
        <div class="info">
          <div class="info_1" @click="routeBtn(1)">
            <img src="@/assets/about/1.png" class="ImageSS">
          </div>
          <div class="info_2" @click="routeBtn(2)">
            <img src="@/assets/about/2.png" class="ImageSS">
          </div>
          <div class="info_3" @click="routeBtn(3)">
            <img src="@/assets/about/3.png" class="ImageSS">
          </div>
        </div>
        <h2 class="H2">会社環境</h2>
        <div class="env">
          <div class="env_1" @click="$router.push({ path: '/ja/aboutImg', query: { id: 1, name: '生活環境' } })">
            <img src="@/assets/about/4.png" class="ImageSS">
          </div>
          <div class="env_2" @click="$router.push({ path: '/ja/manufacture' })">
            <img src="@/assets/about/5.jpg" class="ImageSS">
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <foot></foot>
    </div>
    <div class="_div">
      <!-- 头部 -->
      <move_header></move_header>
      <!-- 轮播图 -->
      <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img src="@/assets/homePage/ja-关于盛泰banner.png" style="width: 100%;">
        </van-swipe-item>
      </van-swipe>
      <!-- 中间内容区域 -->
      <div class="content">
        <h2>会社情報</h2>
        <div class="info_1" @click="routeBtn(1)">
          <img src="@/assets/about/1.png" alt="">
          <div class="fLot">企業理念</div>
        </div>
        <div class="info_2" @click="routeBtn(2)">
          <div class="fLot">技術開発</div>
          <img src="@/assets/about/2.png" alt="">
        </div>
        <div class="info_3" @click="routeBtn(3)">
          <div class="fLot">盛泰品質</div>
          <img src="@/assets/about/3.png" alt="" />
        </div>
        <h2>会社環境</h2>
        <div class="info_1" @click="$router.push({ path: '/ja/aboutImg', query: { id: 1, name: '生活環境' } })">
          <img src="@/assets/about/4.png" alt="">
          <div class="fLot">生活環境</div>
        </div>
        <div class="info_1" style="margin:5rem 0;" @click="$router.push({ path: '/ja/manufacture' })">
          <img src="@/assets/about/5.jpg" alt="">
          <div class="fLot">製造環境</div>
        </div>
      </div>
      <!-- 底部 -->
      <move_foot></move_foot>
    </div>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { onMounted, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
export default {
  components: { foot, headers, move_header, move_foot, },
  setup() {
    const route = useRouter();
    const state = reactive({
      imgTop: [
        { id: 1, name: '技術開発', url: require('@/assets/about/2.png') },
        { id: 2, name: '盛泰品質', url: require('@/assets/about/3.png') },
      ]
    });
    let methods = {
      routeBtn(id) {
        if(id==1){
          route.push({ path: '/ja/aboutId' })
        }else if(id == 2){
          route.push({ path: '/ja/research' })
        }else if(id == 3){
          route.push({ path: '/ja/quality' })
        }
      }

    }

    onMounted(() => { });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped lang="less">
.images {
  width: 100%;
  height: auto;
  max-height: 600px
}

.ImageSS {
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  overflow: hidden;
}

.box {

  // text-align: center;
  .info_1::after,
  .info_2::after,
  .info_3::after,
  .env_1::after,
  .env_2::after {
    content: '';
    position: absolute;
    width: 100px;
    left: 0;
    bottom: 23px;
    height: 65px;
    width: 100%;
    // background: linear-gradient(to right,red,  blue);
    background: linear-gradient(to right, #1D469A, transparent);
    z-index: 9;
    color: #fff;
    line-height: 65px;
    text-align: left;
    font-size: 26px;
    font-weight: 500;
    padding-left: 50px;
    transition: all 0.5s;
  }

  .info_1:hover img,
  .info_2:hover img,
  .info_3:hover img,
  .env_1:hover img,
  .env_2:hover img {
    transform: scale(1.03);
    transition: all 0.5s;
  }

  .info_1:hover:after,
  .info_2:hover:after,
  .info_3:hover:after,
  .env_1:hover:after,
  .env_2:hover:after {
    padding-left: 100px;
    transition: all 0.5s;
  }

  .info_1::after {
    content: '企業理念';
    font-size: 18px;
  }

  .info_2::after {
    content: '技術開発';
    font-size: 18px;
  }

  .info_3::after {
    content: '盛泰品質';
    font-size: 18px;
  }

  .env_1::after {
    content: '生活環境';
    font-size: 18px;
  }

  .env_2::after {
    content: '製造環境';
    font-size: 18px;
  }

  .info_1,
  .info_2,
  .info_3,
  .env_1,
  .env_2 {

    position: relative;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.5s;
  }

  .content {
    text-align: center;
    margin: 0 auto;
    position: relative;
    max-width: 1920px;
    width: 80%;

    .H2 {
      margin-top: 50px;
      font-size: 1.7rem;
    }
    .H2::before {
      content: "";
      width: 30%;
      height: 2px;
      background-color: #c7000b;
      position: absolute;
      margin-top: 40px;
      left: 50%;
      transform: translateX(-50%);
      // top:55px;
      min-width: 35px;
      max-width: 35px;
    }

    .info {
      display: flex;
      margin: 0 auto;
      justify-content: center;

      .info_1 {
        flex: 2;
        height: 250px;
        margin: 0 10px;
        border-radius: 8px;
        cursor: pointer;
        max-width: 500px;

      }

      .info_2,
      .info_3 {
        height: 250px;
        flex: 1.5;
        margin: 0 10px;
        max-width: 300px;
        border-radius: 8px;
        cursor: pointer;
      }
    }

    .env {
      display: flex;
      margin: 0 auto;
      justify-content: center;

      .env_1 {
        flex: 2;
        max-width: 630px;
        margin: 10px;
        height: 250px;
        cursor: pointer;
        border-radius: 8px;
      }

      .env_2 {
        height: 250px;
        cursor: pointer;
        flex: 1;
        margin: 10px;
        max-width: 500px;
        border-radius: 8px;
      }
    }
  }
}


::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;
    overflow-x: hidden;

    .content {
      h2 {
        text-align: center;
        font-size: 80px;
      }



      h2::before {
        content: "";
        width: 30%;
        height: 8px;
        background-color: #c7000b;
        position: absolute;
        margin-top: 100px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100px;
        max-width: 100px;
      }

      .info_1:hover .fLot,
      .info_2:hover .fLot,
      .info_3:hover .fLot {
        padding-left: 150px;
      }

      .info_1 {
        position: relative;
        cursor: pointer;
        padding: 0 30px;
        box-sizing: border-box;
        height: 900px;

        .fLot {
          position: absolute;
          overflow: hidden;
          bottom: 60px;
          width: 90%;
          height: 180px;
          background: linear-gradient(to right, #1D469A, transparent);
          font-size: 60px;
          color: #ccc;
          line-height: 180px;
          transition: all 0.5s;
          padding-left: 70px;
        }



        img {
          width: 100%;
          height: 100%;
          border-radius: 15px;
          overflow: hidden;
        }
      }

      .info_2,
      .info_3 {
        position: relative;
        cursor: pointer;
        display: inline-block;
        box-sizing: border-box;
        width: 50%;
        height: 800px;

        .fLot {
          position: absolute;
          overflow: hidden;
          bottom: 60px;
          width: 90%;
          height: 180px;
          background: linear-gradient(to right, #1D469A, transparent);
          font-size: 60px;
          color: #ccc;
          line-height: 180px;
          transition: all 0.5s;
          padding-left: 70px;
        }

        .info_2:hover .fLot {
          padding-left: 150px;
        }

        img {
          height: 100%;
          border-radius: 15px;
          overflow: hidden;
          width: 100%;
        }
      }

      .info_2 {
        padding: 30px 15px 0 30px;
      }

      .info_3 {
        padding: 30px 30px 0 15px;
      }
    }
  }
}
</style>